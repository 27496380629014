import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 384.000000 378.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,378.000000) scale(0.100000,-0.100000)">

<path d="M1901 3359 c-319 -67 -604 -268 -769 -541 l-47 -77 -200 -1 c-218 0
-245 -6 -245 -55 0 -53 10 -55 290 -55 222 0 261 -2 274 -16 19 -18 21 -65 4
-82 -9 -9 -115 -12 -412 -12 -302 0 -407 -3 -426 -13 -34 -17 -39 -60 -8 -81
20 -14 72 -16 424 -16 376 0 402 -1 417 -18 22 -24 21 -55 -1 -75 -16 -15 -74
-17 -535 -19 -515 -3 -517 -3 -538 -25 -28 -27 -19 -50 26 -68 28 -13 119 -15
527 -15 537 0 538 0 538 -55 0 -52 -23 -55 -447 -55 -424 0 -433 -1 -433 -56
0 -51 30 -54 463 -54 292 0 396 -3 405 -12 20 -20 14 -65 -10 -82 -19 -14 -63
-16 -278 -16 -274 0 -280 -1 -280 -50 0 -47 11 -50 206 -50 l182 0 41 -82
c196 -402 578 -656 1016 -675 224 -10 395 26 590 122 192 95 342 227 460 404
140 210 199 406 199 661 0 159 -16 256 -64 395 -135 393 -476 691 -887 774
-136 28 -348 28 -482 0z m608 -527 c78 -78 104 -146 99 -257 -4 -85 -36 -178
-65 -189 -9 -4 -121 64 -287 173 -184 121 -287 184 -319 191 -132 34 -267 -79
-267 -221 0 -39 38 -127 65 -149 10 -8 176 -119 369 -247 344 -226 386 -261
334 -271 -9 -2 -178 102 -375 231 -197 129 -397 260 -445 291 -129 84 -175
165 -165 291 5 63 25 114 60 153 32 34 73 29 151 -21 l68 -44 39 20 c22 11 60
22 84 25 89 10 127 -4 265 -95 72 -47 132 -84 134 -82 2 2 36 54 76 114 80
121 99 145 112 145 5 0 36 -26 67 -58z m-208 -503 c150 -100 307 -202 347
-228 297 -187 317 -206 340 -332 13 -66 -2 -146 -37 -202 -38 -63 -82 -63
-172 -3 l-66 45 -44 -22 c-34 -17 -61 -22 -119 -21 -86 0 -97 5 -247 106 -56
37 -106 68 -110 67 -4 0 -44 -56 -88 -124 -64 -99 -85 -125 -103 -125 -32 0
-108 80 -140 148 -23 49 -27 70 -27 147 1 79 4 97 30 148 15 31 34 57 41 57 7
0 133 -79 280 -176 147 -96 278 -180 291 -185 42 -16 131 -11 171 10 137 70
165 250 55 352 -15 15 -181 128 -369 252 -188 123 -344 229 -348 236 -10 15 2
31 23 31 9 0 141 -82 292 -181z"/>
<path d="M2371 2708 c-39 -61 -71 -111 -69 -113 17 -14 219 -145 222 -145 10
0 36 105 36 144 -1 53 -29 135 -60 173 -50 61 -50 61 -129 -59z"/>
<path d="M1528 2758 c-34 -66 -31 -161 8 -224 16 -26 43 -57 59 -70 l30 -23 0
93 c0 87 2 96 31 143 l31 50 -56 37 c-31 20 -62 36 -68 36 -7 0 -23 -19 -35
-42z"/>
<path d="M2822 1887 c13 -57 2 -117 -33 -183 -16 -31 -29 -57 -29 -59 0 -1 26
-19 57 -40 66 -44 79 -41 105 26 21 56 22 114 3 170 -15 42 -63 101 -96 120
-15 8 -16 4 -7 -34z"/>
<path d="M1903 1883 c-26 -62 -22 -159 10 -224 14 -30 39 -67 55 -82 l29 -28
71 109 c40 59 72 111 72 115 0 5 -130 93 -208 141 -8 5 -18 -5 -29 -31z"/>
<path d="M454 796 c-29 -29 -34 -41 -34 -79 0 -59 26 -90 104 -121 33 -13 67
-31 78 -40 40 -34 9 -76 -56 -76 -53 0 -92 28 -101 71 -6 28 -9 30 -22 16 -19
-18 -21 -84 -4 -115 16 -31 83 -62 131 -62 90 0 150 55 150 137 0 54 -33 93
-102 122 -105 44 -117 55 -98 92 15 27 79 26 115 -3 l28 -22 18 23 c27 33 24
48 -13 71 -24 14 -51 20 -96 20 -59 0 -66 -2 -98 -34z"/>
<path d="M754 794 c-11 -59 2 -205 24 -262 27 -69 70 -142 85 -142 7 0 28 28
47 62 19 33 40 61 45 61 6 0 26 -28 44 -62 23 -40 40 -61 50 -59 21 4 87 138
106 215 10 37 15 93 13 140 l-3 78 -35 3 -35 3 0 -118 c0 -95 -4 -124 -18
-151 -10 -18 -24 -32 -30 -29 -24 7 -39 82 -40 190 l-1 108 -39 -3 -40 -3 2
-90 c2 -95 -17 -182 -44 -205 -13 -10 -18 -6 -31 26 -17 39 -25 176 -14 239 5
29 4 30 -37 33 -41 3 -42 2 -49 -34z"/>
<path d="M1250 793 c-7 -23 -10 -107 -8 -213 l3 -175 49 -3 48 -3 -8 53 c-12
69 -11 258 0 326 l9 52 -41 0 c-39 0 -42 -2 -52 -37z"/>
<path d="M1439 813 c-10 -12 -15 -61 -17 -190 -5 -222 -5 -223 54 -223 l45 0
-8 48 c-18 111 -5 142 63 142 41 0 43 2 53 38 15 53 15 55 -24 48 -19 -3 -51
-9 -70 -12 -34 -6 -35 -5 -35 24 0 48 30 77 79 76 22 -1 47 -7 55 -13 10 -8
16 -8 23 1 16 23 32 66 26 72 -4 3 -57 6 -118 6 -92 0 -115 -3 -126 -17z"/>
<path d="M1753 823 c-31 -6 -42 -24 -50 -79 l-6 -48 29 19 c46 30 85 48 91 41
4 -3 1 -46 -5 -95 -13 -98 -15 -182 -6 -230 6 -30 9 -31 57 -31 l50 0 -7 33
c-24 112 -24 279 -1 316 10 17 30 13 59 -10 15 -12 28 -20 30 -18 11 13 26 50
26 64 0 28 -62 45 -157 44 -48 -1 -98 -4 -110 -6z"/>
<path d="M2124 811 c-37 -22 -59 -76 -49 -120 8 -39 50 -76 115 -101 68 -27
80 -37 80 -64 0 -33 -19 -46 -70 -46 -56 0 -85 19 -101 67 -11 34 -13 35 -25
18 -8 -10 -14 -36 -14 -56 0 -74 76 -127 167 -116 116 13 172 138 95 214 -15
14 -56 38 -91 52 -35 14 -69 34 -75 44 -30 47 65 79 110 36 23 -22 24 -22 44
-4 29 26 25 52 -12 75 -44 27 -131 27 -174 1z"/>
<path d="M2429 808 c-7 -13 -12 -74 -13 -153 -1 -118 1 -135 23 -178 28 -57
56 -78 115 -84 68 -8 113 19 145 86 24 51 26 66 25 181 0 148 -8 170 -60 170
-35 0 -36 -1 -30 -32 3 -18 9 -76 12 -128 7 -109 -8 -162 -50 -181 -32 -15
-51 -7 -76 33 -19 30 -21 44 -16 143 2 61 7 122 11 138 7 26 6 27 -33 27 -32
0 -44 -5 -53 -22z"/>
<path d="M2809 809 c-15 -29 -25 -321 -12 -369 l9 -35 113 -3 112 -3 29 30
c46 46 36 125 -24 180 -32 30 -32 34 0 76 32 42 34 89 5 118 -19 19 -35 22
-120 25 -95 4 -100 3 -112 -19z m149 -45 c36 -10 28 -47 -20 -88 -55 -48 -68
-47 -68 7 0 44 13 70 40 80 21 7 22 7 48 1z m13 -176 c47 -50 46 -100 -3 -117
-17 -6 -37 -11 -44 -11 -26 0 -54 39 -54 75 0 30 5 37 38 54 20 11 38 20 39
21 1 0 12 -10 24 -22z"/>
<path d="M3165 820 c-13 -21 8 -92 41 -141 19 -28 34 -54 34 -58 0 -3 -18 -30
-40 -59 -37 -50 -60 -105 -60 -144 0 -14 9 -18 39 -18 l40 0 11 55 c7 32 22
67 36 83 l24 27 20 -23 c10 -13 25 -50 31 -83 l12 -59 43 0 44 0 0 38 c0 39
-26 104 -60 153 l-21 29 32 44 c26 37 32 55 33 103 l1 58 -36 3 -36 3 -5 -55
c-2 -30 -13 -67 -23 -82 l-18 -28 -18 23 c-10 12 -21 43 -24 69 -4 26 -8 53
-10 60 -5 15 -81 16 -90 2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
